// renderless
@import '~@app_aob_corporate_scss/_functions.scss';
@import '~@app_aob_corporate_scss/_variables.scss';
@import '~@app_aob_corporate_scss/_mixins.scss';
@import '~@app_aob_corporate_scss/_media.scss';

.aob-wrapper {
    display: flex;
    flex-direction: column;
}

.error-container {
    flex: 1 1 auto;
    padding: 200px 0 100px;
    text-align: center;
    font: normal 16px/23px $acumin-pro;

    @include media('>=xlarge') {
        padding: 300px 0 0;
    }

    .aob-cta {
        margin-top: 30px;
        font-size: 13px;
    }
}

.aob-email-signup {
    max-height: 100px;
    margin-bottom: 0;
}

.aob-footer__social-links {
    display: none;
}

.aob-footer__brand-logo {
    margin-bottom: 0;
}
